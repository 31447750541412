<template>
	<div class="realtimeauditing">

		<el-card class="box-card mb10">
			<el-form :model="queryinfo" class="queryinfo" label-suffix="：">
				<el-row :gutter="24">
					<el-col :span="6">
						<el-form-item label="开始日期">
							<el-date-picker

							 v-model="queryinfo.start"
							 format="yyyy-MM-dd"
							 placeholder="选择日期时间"
							 type="date"
							 value-format="yyyy-MM-dd"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="结束日期">
							<el-date-picker
							 v-model="queryinfo.end"
							 ormat="yyyy-MM-dd"
							 placeholder="选择日期时间"
							 type="date"
							 value-format="yyyy-MM-dd"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="微站Id">
							<el-select
							 v-model="queryinfo.weiId"
							 placeholder="请选择"
							 style="width: 80%"
							 @change="getCodes">
								<el-option label="QA232200000874" value="QA232200000874"/>
								<el-option label="QA232200000875" value="QA232200000875"/>
								<el-option label="QA232200000872" value="QA232200000872"/>
								<el-option label="QA232200000873" value="QA232200000873"/>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="类型">
							<el-select
							 v-model="queryinfo.type"
							 placeholder="请选择"
							 style="width: 80%">
								<el-option label="小时数据" value="1"/>
								<el-option label="日数据" value="2"/>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="检测因子">
							<el-select
							 v-model="queryinfo.codes"
							 multiple
							 placeholder="请选择"
							 style="width: 80%"
							 @change="getCodes">
								<el-option
								 v-for="item in codesList"
								 :key="item.value"
								 :label="item.label"
								 :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<div class="searchorrestbutton">
					<el-button class="searchbutton" icon="el-icon-search" size="small" type="primary" @click="getWeiZhanDataList">
						搜索
					</el-button>
					<el-button  class="resetbutton" icon="el-icon-refresh-left" size="small">重置</el-button>
					<el-button class="resetbutton" @click="exportFile()" icon="el-icon-refresh-left" size="small">导出</el-button>

				</div>
			</el-form>
		</el-card>
		<el-card class="box-card">
			<div class="mb10">
			</div>
			<!-- 搜索与添加区域 -->
			<el-table
			 :border="true"
			 :data="tableData"
			 header-align="center"
			 max-height="600px"
			 size="mini"
			 style="width: 100%">
				<el-table-column label="日期" prop="dataTimeStr"/>
				<el-table-column
				 v-for="item in codesList"
				 :prop="item.value"
				>
					<template slot="header" slot-scope="scope">
						<span>{{ item.label }}<br>{{item.value}} </span>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
	</div>
</template>
<script>
import {getWeiZhanDataList,getWeiZhanDataFile} from "@/utils/masterdata.js";
import echartMixins from "@/utils/resizeMixins";

export default {
	data() {
		return {
			chart: null,
			blueMax: [],
			wzAvg: [],
			diff: [],
			line: 0,

			codesList: [
				{label: "温度（℃）", value: "a01001Avg"},
				{label: "湿度（%）", value: "a01002Avg"},
				{label: "气压（HPA）", value: "a01006Avg"},
				{label: "风速（M/S）", value: "a01007Avg"},
				{label: "风向（°）", value: "a01008Avg"},
				{label: "臭氧（ug/m3）", value: "a05024Avg"},
				{label: "二氧化氮（ug/m3）", value: "a21004Avg"},
				{label: "voc（ug/m3）", value: "a24088Avg"},
				{label: "pm10（ug/m3）", value: "a34002Avg"},
				{label: "pm2.5（ug/m3）", value: "a34004Avg"},

			],
			centerDialogVisible: false,
			inputstates: false,
			addobtntate: "添加",
			queryinfo: {
				index: 1,
				pageSize: 10,
				
				type: "1",
				codes: ["a01001Avg", "a01002Avg", "a01006Avg", "a01007Avg", "a01008Avg",
					"a05024Avg", "a21004Avg", "a24088Avg", "a34002Avg", "a34004Avg",
				],
				weiId: "QA232200000874"
			},
			userList: [],
			total: 0,
			tableData: [],
		};
	},
	mixins: [echartMixins],
	mounted() {
		let queryinfo = {...this.queryinfo}
		queryinfo.start = this.getStartTime()
		queryinfo.end = this.getEndTime()
		this.queryinfo={...queryinfo}
		this.getWeiZhanDataList()
		this.draw()

	},
	methods: {
		async exportFile(){
			let queryinfo = {
				...this.queryinfo,
				end: this.queryinfo.end + " 00:00:00",
				start: this.queryinfo.start + " 00:00:00",
				codes: this.queryinfo.codes.join(',')
			}
			const body = await getWeiZhanDataFile({...queryinfo});
			console.log(body)
			let blob = new Blob([body], {
				type: 'application/vnd.ms-excel',
			});
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement('a');
			a.href = url;
			a.download = '导出文件的名称.xls';
			a.click();
			window.URL.revokeObjectURL(url);
		},
		getCodes(e) {
			console.log(e)
		},

		getEndTime() {
			let date = new Date()
			let year = date.getFullYear()
			let month = date.getMonth() + 1
			// 这里传入的是整数时间，返回的是下个月的第一天，因为月份是0-11
			let nextMonthFirthDay = new Date(year, month, 1) // 下个月的第一天
			let oneDay = 1000 * 60 * 60 * 24 // 一天的时间毫秒数
			let endDay = new Date(nextMonthFirthDay - oneDay)
			let day = endDay.getDate() // 本月最后一天

			// 这里传入的是字符串格式的时间，返回的是传入字符串的时间
			console.log(year + '-' + month + '-' + day)
			 return year + '-' + month + '-' + day
		},

		getStartTime() {
			let date = new Date()
			date.setDate(1) // 将当前时间的日期设置成第一天
			let year = date.getFullYear()  // 得到当前年份
			let month = date.getMonth() + 1 // 得到当前月份（0-11月份，+1是当前月份）
			month = month > 10 ? month : '0' + month // 补零
			let day = date.getDate() // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
// console.log(month) 03
			return year + '-' + month + '-' + day// 这里传入的是字符串
		},
		async getWeiZhanDataList() {
			let queryinfo = {
				...this.queryinfo,
				end: this.queryinfo.end + " 00:00:00",
				start: this.queryinfo.start + " 00:00:00",
				codes: this.queryinfo.codes.join(',')
			}
			const {body} = await getWeiZhanDataList({...queryinfo});
			this.tableData = body.reverse()
			// this.total = body.count
		},

	},
};
</script>
<style lang="scss">
.realtimeauditing {
	.el-form-item__content {
		width: 100%;

	}
}

</style>
